<script>
export default {
  methods: {
    catalogLink({ slug, catalogState, complementaryParameterValue, page, withoutParameterValue }) {
      const query = {}

      function parametersToUrlString(parameters) {
        const parts = []
        const selectedParameters = parameters
          .map(parameter => {
            let values = null
            if (parameter.selectedRange) {
              values = parameter.selectedRange.map(number => number * 1000)
            } else {
              values = parameter.values
                .filter(
                  v =>
                    (v.isSelected && (!withoutParameterValue || withoutParameterValue.id !== v.id)) ||
                    (complementaryParameterValue && v.id === complementaryParameterValue.id),
                )
                .map(v => v.slug)
            }
            return {
              parameter: parameter,
              values: values,
            }
          })
          .filter(p => p.values.length > 0)

        selectedParameters.forEach(p => {
          if (p.parameter.selectedRange) {
            parts.push(`${p.parameter.slug}_${p.values.join('.')}`)
          } else {
            p.values.forEach(value => {
              parts.push(value)
            })
          }
        })

        return parts
          .sort((a, b) => {
            return a.localeCompare(b)
          })
          .join('__')
      }

      let selectedImportantParameterValues = 0
      catalogState.parameters.forEach(p => {
        p.values.forEach(v => {
          if (
            (v.isSelected && (!withoutParameterValue || withoutParameterValue.id !== v.id)) ||
            (complementaryParameterValue && v.id === complementaryParameterValue.id)
          ) {
            selectedImportantParameterValues++
          }
        })
      })

      const importantParameters = []
      const nfParameters = []
      catalogState.parameters.forEach(p => {
        if (p.importantFilter && selectedImportantParameterValues < 2 - (withoutParameterValue ? 1 : 0)) {
          importantParameters.push(p)
        } else {
          nfParameters.push(p)
        }
      })

      const nfParametersString = parametersToUrlString(nfParameters)
      if (nfParametersString) {
        query.nf = nfParametersString
      }
      const importantParametersString = parametersToUrlString(importantParameters)

      // Add sort
      if (
        catalogState.activeSort &&
        catalogState.activeSort !== this.$themeSettings.catalog.defaultSortType &&
        catalogState.activeSort !== 'SearchSort'
      ) {
        query.sort = catalogState.activeSort
      }

      // Add price filter
      if (
        catalogState.filters.filterPrice &&
        (catalogState.filters.filterPrice.min || catalogState.filters.filterPrice.max)
      ) {
        query.price = [catalogState.filters.filterPrice.min, catalogState.filters.filterPrice.max].join('-')
      }

      // Add in stock filter
      if (catalogState.filters.filterInStock && catalogState.filters.filterInStock.enabled) {
        query.stock = 1
      }

      // Add page
      if (page) {
        query.p = page
      }

      // Add tags
      const selectedTags = this.catalogState.tags && this.catalogState.tags.filter(tag => tag.isSelected)
      if (selectedTags && selectedTags.length > 0) {
        query.tags = selectedTags.map(tag => tag.code).join('.')
      }

      // Add search
      if (catalogState.search) {
        query.s = catalogState.search
      }

      // If catalogState.search is empty string and no slug defined then we set path to 'hledat'
      let slugSearch = catalogState.search || !slug ? this.localePath({ name: 'search' }).replace('/', '') : slug
      const slugLocaleReqex = new RegExp(`${this.$i18n.locale}/`)
      slugSearch = slugSearch.replace(slugLocaleReqex, '')

      const path = this.localePath({
        name: 'all',
        params: [`${slugSearch}` + (importantParametersString ? `/${importantParametersString}` : '')],
      })
      const pathWithoutImportantParameters = this.localePath({
        name: 'all',
        params: [slugSearch],
      })
      const keys = Object.keys(query)
      return {
        query,
        importantParametersString,
        path,
        pathWithoutImportantParameters,
        url:
          pathWithoutImportantParameters +
          (keys.length > 0
            ? '?' +
              keys
                .map(key => ({ key, value: query[key] }))
                .map(({ key, value }) => `${key}=${value}`)
                .join('&')
            : ''),
        urlWithImportantParameters:
          path +
          (keys.length > 0
            ? '?' +
              keys
                .map(key => ({ key, value: query[key] }))
                .map(({ key, value }) => `${key}=${value}`)
                .join('&')
            : ''),
        rel: importantParametersString === '' ? 'nofollow' : null,
      }
    },
  },
}
</script>
