import CatalogFiltersCheckbox from '@theme/components/catalog/CatalogFiltersCheckbox'
import DetectMobileMixin from '~/mixins/DetectMobileMixin'

export default {
  mixins: [DetectMobileMixin],
  components: {
    CatalogFiltersCheckbox,
  },
  props: {
    catalogState: Object,
  },
  methods: {
    update() {
      if (!this.isMobileDetected) this.$scrollTo('#PageCatalog__anchor')
      this.$store.dispatch('catalog/UPDATE_FILTERS')
    },
  },
  computed: {
    tags() {
      return this.catalogState.tags.filter(
        tag => !this.catalogState.slicedTags || !this.catalogState.slicedTags.includes(tag.code),
      )
    },
  },
}
