import { hydrateWhenVisible } from 'vue-lazy-hydration'
import CategoryVideo from '@theme/components/utils/CategoryVideo'
import ContentBuilder from '@theme/components/utils/ContentBuilder'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  components: {
    CategoryVideo: hydrateWhenVisible(CategoryVideo),
    ContentBuilder,
    Wysiwyg,
  },
  props: {
    content: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      contentVisible: false,
    }
  },
  computed: {
    hasContent() {
      return (
        this.content.content && Array.isArray(this.content.content.blocks) && this.content.content.blocks.length > 0
      )
    },
  },
  methods: {
    showContent() {
      this.contentVisible = true
    },
  },
}
