import ArticleBox from '@theme/components/content/ArticleBox'
import _ from 'lodash'

export default {
  components: { ArticleBox },
  computed: {
    articles() {
      return _.get(this.$store, 'state.search.catalog.results.articles.entities', [])
    },
  },
}
