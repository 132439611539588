import CatalogFiltersParameterValueMixin from '~/mixins/CatalogFiltersParameterValueMixin'
import CatalogLink from '~/mixins/CatalogLink'

export default {
  mixins: [CatalogFiltersParameterValueMixin, CatalogLink],
  methods: {
    linkClicked(e) {
      if (!e.metaKey && !this.parameterValueIsEmpty) {
        e.stopPropagation()
        e.preventDefault()
        this.parameterValue.isSelected = !this.parameterValue.isSelected
        this.update()
      }
    },
  },
  computed: {
    filterLink() {
      return this.catalogLink({
        slug: this.$store.state.catalog.slug,
        catalogState: this.catalogState,
        complementaryParameterValue: this.parameterValue,
      })
    },
  },
}
