export default {
  props: {
    catalogState: Object,
  },
  computed: {
    isFiltered() {
      // TODO: Move to global state, it's duplicite with code in PageCatalog
      return (
        this.selectedTags.length > 0 ||
        this.selectedValues.length > 0 ||
        this.selectedNumericalParameters.length > 0 ||
        this.catalogState.filters.filterInStock.enabled ||
        (this.catalogState.filters.filterPrice.min || this.catalogState.filters.filterPrice.max)
      )
    },
    priceFilterText() {
      // TODO: Move to extendable code
      if (this.catalogState.filters.filterPrice.min || this.catalogState.filters.filterPrice.max) {
        return this.$t('CatalogFiltersActive.priceFilterLabel', {
          min: this.catalogState.filters.filterPrice.min
            ? this.catalogState.filters.filterPrice.min
            : this.catalogState.productsPriceRange.min,
          max: this.catalogState.filters.filterPrice.max
            ? this.catalogState.filters.filterPrice.max
            : this.catalogState.productsPriceRange.max,
          currencySymbol: this.$store.state.currency.activeCurrency.symbol,
        })
      }
    },
    selectedNumericalParameters() {
      // TODO: Move to global state, it's duplicite with code in PageCatalog
      return this.catalogState.parameters.filter(parameter => parameter.selectedRange)
    },
    selectedTags() {
      // TODO: Move to global state, it's duplicite with code in PageCatalog
      return this.catalogState.tags.filter(tag => tag.isSelected)
    },
    selectedValues() {
      // TODO: Move to global state, it's duplicite with code in PageCatalog
      const ret = []
      this.catalogState.parameters.forEach(parameter => {
        parameter.values.filter(value => value.isSelected).forEach(value => {
          ret.push(value)
        })
      })
      return ret
    },
    parametersSelected() {
      return this.$themeSettings.components.CatalogFiltersActive.showParameterName
        ? this.catalogState.parameters
            .map(parameter => ({
              id: parameter.id,
              names: parameter.names,
              values: parameter.values.filter(value => value.isSelected),
            }))
            .filter(parameter => parameter.values && parameter.values.length > 0)
        : null
    },
  },
  methods: {
    deselectPriceFilter() {
      // TODO: Move to extendable code
      this.catalogState.filters.filterPrice = {
        min: null,
        max: null,
      }
      this.$catalogEventBus.$emit('catalog.updateFilters')
    },
    deselectInStockFilter() {
      // TODO: Move to extendable code
      this.catalogState.filters.filterInStock.enabled = false
      this.$catalogEventBus.$emit('catalog.updateFilters')
    },
    deselectNumericalParameter(parameter) {
      parameter.selectedRange = null
      this.$catalogEventBus.$emit('catalog.updateFilters')
    },
    deselectValue(value) {
      // TODO: Move to extendable code
      value.isSelected = false
      this.$catalogEventBus.$emit('catalog.updateFilters')
    },
    deselectTag(tag) {
      // TODO: Move to extendable code
      tag.isSelected = false
      this.$catalogEventBus.$emit('catalog.updateFilters')
    },
    removeAllFilters() {
      this.$catalogEventBus.$emit('catalog.removeAllFilters')
    },
  },
}
