import AppButton from '@theme/components/atom/AppButton'
import CatalogFilters from '@theme/components/catalog/CatalogFilters'

export default {
  components: {
    AppButton,
    CatalogFilters,
  },
  props: {
    catalogState: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      displayCatalogFilters: false,
    }
  },
  methods: {
    updateFilters() {
      this.$emit('updateFilters')
    },
  },
}
