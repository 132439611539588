import AppImage from '@theme/components/atom/AppImage'
import eventDefinitions from '@/events/eventDefinitions'

export default {
  components: {
    AppImage,
  },
  props: {
    banner: Object,
  },
  mounted() {
    this.$eventBus.$emit(eventDefinitions.PROMOTION.IMPRESSION, {
      promotion: {
        name: this.banner.title,
      },
    })
  },
  methods: {
    clicked() {
      this.$eventBus.$emit(eventDefinitions.PROMOTION.CLICK, {
        promotion: {
          name: this.banner.title,
        },
      })
    },
  },
}
