import FrequentQuestionBox from '@theme/components/content/FrequentQuestionBox'
import _ from 'lodash'
import slugify from 'slugify'

export default {
  components: { FrequentQuestionBox },
  computed: {
    items() {
      const items = _.get(this.$store, 'state.search.catalog.results.faq_questions.entities', null)
      return items
        ? items.map(item => {
            return { ...item, slug: slugify(item.title, { lower: true }) }
          })
        : []
    },
  },
}
