import { render, staticRenderFns } from "./CatalogFiltersWidgetColors.vue?vue&type=template&id=4710a178&"
import script from "./CatalogFiltersWidgetColors.js?vue&type=script&lang=js&"
export * from "./CatalogFiltersWidgetColors.js?vue&type=script&lang=js&"
import style0 from "./CatalogFiltersWidgetColors.scss?vue&type=style&index=0&prod&lang=scss&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports