import AppImage from '@theme/components/atom/AppImage'
import CatalogFiltersParameterValueLink from '@theme/components/catalog/CatalogFiltersParameterValueLink'
import CatalogFiltersWidgetMixin from '~/mixins/CatalogFiltersWidgetMixin'
import CatalogFiltersWidgetSimpleSortValuesMixin from '~/mixins/CatalogFiltersWidgetSimpleSortValuesMixin'

export default {
  mixins: [CatalogFiltersWidgetMixin, CatalogFiltersWidgetSimpleSortValuesMixin],
  components: {
    AppImage,
    CatalogFiltersParameterValueLink,
  },
  data() {
    return {
      parameterValues: null,
    }
  },
  mounted() {
    this.fetchValues()
  },
  methods: {
    fetchValues() {
      this.parameterValues = this.parameter.values.filter(
        parameterValue =>
          parameterValue.cms &&
          parameterValue.cms.parameter_value_image &&
          parameterValue.cms.parameter_value_image.length > 0 &&
          parameterValue.cms.parameter_value_image[0].fileKey,
      )
    },
  },
  watch: {
    parameter() {
      this.fetchValues()
    },
  },
}
