import CatalogFiltersWidgetMixin from '~/mixins/CatalogFiltersWidgetMixin'
import RangeSlider from '@theme/components/utils/RangeSlider'

export default {
  mixins: [CatalogFiltersWidgetMixin],
  components: { RangeSlider },
  methods: {
    update(value) {
      if (value[0] === this.parameter.range[0] && value[1] === this.parameter.range[1]) {
        this.parameter.selectedRange = null
      } else {
        this.parameter.selectedRange = value
      }
      this.$store.dispatch('catalog/UPDATE_FILTERS')
    },
  },
}
