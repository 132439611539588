var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"CatalogFiltersWidgetColors d-flex flex-wrap"},_vm._l((_vm.parameter.values),function(parameterValue){return _c('CatalogFiltersParameterValueLink',{key:parameterValue.id,attrs:{"parameter":_vm.parameter,"parameter-value":parameterValue,"catalog-state":_vm.catalogState},scopedSlots:_vm._u([{key:"default",fn:function({ parameterValueIsEmpty }){return (parameterValue.cms && (parameterValue.cms.color_hex || _vm.hasImage(parameterValue)))?_c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(parameterValue.names[_vm.$store.state.locale.contentLocale]),expression:"parameterValue.names[$store.state.locale.contentLocale]"}],staticClass:"CatalogFiltersWidgetColors__button mr-1 mb-1",class:{
        'CatalogFiltersWidgetColors__button--selected': parameterValue.isSelected,
        'CatalogFiltersWidgetColors__button--empty': parameterValueIsEmpty,
      },style:({
        ...(parameterValue.cms.color_hex ? {backgroundColor: parameterValue.cms.color_hex} : {}),
        ...(_vm.backgroundImage(parameterValue) ? {backgroundImage: `url(${_vm.backgroundImage(parameterValue)})`}: {}),
      })}):_vm._e()}}],null,true)})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }