<script>
import axios from 'axios'

export default {
  methods: {
    getVideoProvider(videoUrl) {
      if (videoUrl.includes('vimeo')) {
        return 'vimeo'
      }
      if (videoUrl.includes('youtube') || videoUrl.includes('youtu.be')) {
        return 'youtube'
      }
      return 'other_provider'
    },
    async callVimeoApi(videoUrl) {
      try {
        if (!videoUrl) return
        return await axios.get(`https://vimeo.com/api/oembed.json?url=${videoUrl}&responsive=true&autoplay=1`)
      } catch (e) {
        console.error(e.response)
      }
    },
    getYouTubeVideoCode(url) {
      const regExp = /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/
      const match = url.match(regExp)

      return match && match[2].length === 11 ? match[2] : null
    },
  },
}
</script>
