import CatalogFiltersCheckbox from '@theme/components/catalog/CatalogFiltersCheckbox'
import CatalogFiltersParameterValueLink from '@theme/components/catalog/CatalogFiltersParameterValueLink'
import CatalogFiltersParameterValueMixin from '~/mixins/CatalogFiltersParameterValueMixin'
import CatalogLink from '~/mixins/CatalogLink'

export default {
  mixins: [CatalogFiltersParameterValueMixin, CatalogLink],
  components: {
    CatalogFiltersCheckbox,
    CatalogFiltersParameterValueLink,
  },
}
