import RangeSlider from '@theme/components/utils/RangeSlider'

export default {
  components: {
    RangeSlider,
  },
  props: {
    catalogState: {
      type: Object,
      required: true,
    },
  },
  methods: {
    update(value) {
      const newMin = this.catalogState.productsPriceRange.min === value[0] ? null : value[0]
      const newMax = this.catalogState.productsPriceRange.max === value[1] ? null : value[1]

      this.catalogState.filters.filterPrice = {
        min: newMin,
        max: newMax,
      }
      this.$store.dispatch('catalog/UPDATE_FILTERS')
    },
  },
}
