import AppTitle from '@theme/components/atom/AppTitle'
import Breadcrumbs from '@theme/components/molecule/Breadcrumbs'
import ContentBuilder from '@theme/components/utils/ContentBuilder'
import LayoutBase from '@theme/components/shop/LayoutBase'
import Wysiwyg from '@theme/components/utils/Wysiwyg'

export default {
  components: {
    AppTitle,
    Breadcrumbs,
    ContentBuilder,
    LayoutBase,
    Wysiwyg,
  },
  props: {
    faqCategory: {
      type: Object,
    },
    faqQuestions: {
      type: Array,
    },
    faqCategoryIcon: String,
  },
  data() {
    return {
      activeQuestionId: null,
    }
  },
  computed: {
    faqByCategory() {
      let byCategory = {}
      this.faqQuestions.forEach(q => {
        if (q.faq_categories_reference && q.faq_categories_reference[0]) {
          const categoryId = q.faq_categories_reference[0].entityId
          byCategory[categoryId] = byCategory[categoryId] || []
          byCategory[categoryId].push(q)
        }
      })
      return byCategory
    },
  },
  methods: {
    toggle(questionId) {
      this.activeQuestionId = this.activeQuestionId === questionId ? null : questionId
    },
  },
}
