/*
for details on Vimeo video embedding see:
https://developer.vimeo.com/api/oembed/videos
*/
import VideoMixin from '~/mixins/VideoMixin'

export default {
  mixins: [VideoMixin],
  props: {
    video: Object,
  },
  data() {
    return {
      id: `player-${Math.round(Math.random() * 10000)}`,
      iframe: '',
      videoProvider: '',
      ytPlayer: null,
    }
  },
  methods: {
    ytPlayerInit() {
      this.ytPlayer = new YT.Player(this.id, {
        height: '780',
        width: '1280',
        videoId: this.getYouTubeVideoCode(this.video.video_url),
        events: {
          onReady: onPlayerReady,
        },
      })
      function onPlayerReady(event) {
        event.target.playVideo()
      }
    },
  },
  async mounted() {
    this.videoProvider = this.getVideoProvider(this.video.video_url)
    if (this.videoProvider === 'vimeo') {
      const response = await this.callVimeoApi(this.video.video_url)
      this.iframe = response.data.html
    } else if (this.videoProvider === 'youtube') {
      const tag = document.createElement('script')

      tag.src = 'https://www.youtube.com/iframe_api'
      const firstScriptTag = document.getElementsByTagName('script')[0]
      firstScriptTag.parentNode.insertBefore(tag, firstScriptTag)

      // This gets called by YT after load
      window.onYouTubeIframeAPIReady = () => {
        this.ytPlayerInit()
      }

      // Call immediately if YT is already loaded
      if (window.yt) {
        this.ytPlayerInit()
      }
    }
  },
}
