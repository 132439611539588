import TooltipOpener from '@theme/components/molecule/TooltipOpener'

export default {
  components: {
    TooltipOpener,
  },
  props: {
    initOpened: {
      type: Boolean,
      default: true,
    },
    title: String,
    tooltip: String,
  },
  data() {
    return {
      enableIconRotation: !this.$themeSettings.components.CatalogFiltersBox.toggleIcon.closed,
      iconClosed: !!this.$themeSettings.components.CatalogFiltersBox.toggleIcon.closed
        ? require('@icon/up.svg?raw')
        : null,
      iconOpened: require('@icon/down.svg?raw'),
      opened: this.initOpened,
    }
  },
}
