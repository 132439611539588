import Modal from '@theme/components/utils/Modal'
import VideoMixin from '~/mixins/VideoMixin'
import VideoPlayer from '@theme/components/utils/VideoPlayer'
import _ from 'lodash'

export default {
  mixins: [VideoMixin],
  components: {
    Modal,
    VideoPlayer,
  },
  props: {
    videos: Array,
  },
  data() {
    return {
      selectedVideo: {},
      thumbnails: [],
    }
  },
  async mounted() {
    const promises = this.videos.map(
      video => (this.getVideoProvider(video.video_url) === 'vimeo' ? this.callVimeoApi(video.video_url) : null),
    )
    const response = await Promise.all(promises)
    this.thumbnails = response.map(video => video.data.thumbnail_url_with_play_button)
  },
  methods: {
    openVideo(index = 0) {
      this.selectedVideo = this.videos[index]
      this.$refs.modal.show()
    },
  },
}
