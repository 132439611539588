<script>
import CatalogFiltersBox from '@theme/components/catalog/CatalogFiltersBox'
import CatalogFiltersWidgetCheckboxes from '@theme/components/catalog/CatalogFiltersWidgetCheckboxes'
import CatalogFiltersWidgetColors from '@theme/components/catalog/CatalogFiltersWidgetColors'
import CatalogFiltersWidgetImages from '@theme/components/catalog/CatalogFiltersWidgetImages'
import CatalogFiltersWidgetLinks from '@theme/components/catalog/CatalogFiltersWidgetLinks'
import CatalogFiltersWidgetSelect from '@theme/components/catalog/CatalogFiltersWidgetSelect'
import CatalogFiltersWidgetSlider from '@theme/components/catalog/CatalogFiltersWidgetSlider'

export default {
  components: {
    CatalogFiltersBox,
  },
  props: {
    catalogState: {
      type: Object,
      default: null,
      required: true,
    },
    parameter: {
      type: Object,
      default: null,
      required: true,
    },
  },
  data() {
    return {
      componentTypes: {
        colors: CatalogFiltersWidgetColors,
        images: CatalogFiltersWidgetImages,
        links: CatalogFiltersWidgetLinks,
        select: CatalogFiltersWidgetSelect,
        slider: CatalogFiltersWidgetSlider,
      },
    }
  },
  computed: {
    fullTitle() {
      return this.parameter.names[this.$store.state.locale.contentLocale]
    },
    parameterWidget() {
      const widget = this.parameter.cms.parameter_widget
      for (let [type, component] of Object.entries(this.componentTypes)) {
        if (widget.includes(type)) {
          return {
            component,
            type,
          }
        }
      }
      return {
        component: CatalogFiltersWidgetCheckboxes,
      }
    },
  },
}
</script>
