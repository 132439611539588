<script>
export default {
  data() {
    return {
      sortedValues: [],
    }
  },
  watch: {
    parameter() {
      // Sort values again when filters update
      this.sortValues()
    },
  },
  created() {
    this.sortValues()
  },
  methods: {
    sortValues() {
      this.sortedValues = this.parameter.values.sort((a, b) => {
        return b.weight - a.weight
      })
    },
  },
}
</script>
