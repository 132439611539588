import CatalogFiltersParameterValueLink from '@theme/components/catalog/CatalogFiltersParameterValueLink'
import CatalogFiltersWidgetMixin from '~/mixins/CatalogFiltersWidgetMixin'
import CatalogFiltersWidgetSimpleSortValuesMixin from '~/mixins/CatalogFiltersWidgetSimpleSortValuesMixin'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'

export default {
  mixins: [CatalogFiltersWidgetMixin, CatalogFiltersWidgetSimpleSortValuesMixin, ImageUrlMixin],
  components: {
    CatalogFiltersParameterValueLink,
  },
  methods: {
    backgroundImage(parameterValue) {
      if (this.hasImage(parameterValue)) {
        return this.imageUrl({
          src: parameterValue.cms.parameter_value_image[0].fileKey,
          width: 64,
          height: 64,
          crop: true,
        })
      }
    },
    hasImage(parameterValue) {
      return parameterValue.cms.parameter_value_image && parameterValue.cms.parameter_value_image.length > 0
    },
  },
}
