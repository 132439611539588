import AppTitle from '@theme/components/atom/AppTitle'
import Container from '@theme/components/utils/Container'
import HeadingLevel from '~/mixins/HeadingLevel'
import SubCategoryBox from '@theme/components/category/SubCategoryBox'
import UtilityGridMixin from '~/mixins/UtilityGridMixin'

export default {
  mixins: [HeadingLevel, UtilityGridMixin],
  components: {
    AppTitle,
    Container,
    SubCategoryBox,
  },
  props: {
    subcategories: {
      type: Array,
      required: true,
    },
    imageWidth: {
      type: Number,
      default() {
        return this.$themeSettings.components.CatalogSubCategories.image.width
      },
    },
    imageHeight: {
      type: Number,
      default() {
        return this.$themeSettings.components.CatalogSubCategories.image.height
      },
    },
    useContainerComponent: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      columnGridClass: '',
    }
  },
  created() {
    this.columnGridClass = this.computeColumnClass(this.$themeSettings.components.CatalogSubCategories.countPerLine)
  },
}
