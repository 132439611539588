import { cloneDeep } from 'lodash'

export default {
  props: {
    isSearch: {
      type: Boolean,
      default() {
        return false
      },
    },
    activeSort: {
      type: String,
      required: true,
    },
  },
  data() {
    // Available options: DefaultSort, MostSoldSort, LatestSort, PriceSortASC, PriceSortDESC, NameSortASC, NameSortDESC
    const sortOptions =
      this.$themeSettings.components.CatalogSorting.options &&
      this.$themeSettings.components.CatalogSorting.options.length > 0
        ? cloneDeep(this.$themeSettings.components.CatalogSorting.options)
        : ['DefaultSort', 'MostSoldSort', 'PriceSortASC', 'PriceSortDESC']
    if (this.isSearch && !sortOptions.includes('SearchSort')) {
      sortOptions.push('SearchSort')
    }
    return {
      sortOptions,
      arrowIcon: require('@icon/down-arrow.svg?raw'),
    }
  },
  methods: {
    changeSort(code) {
      this.$emit('changeSort', code)
    },
  },
}
