import { hydrateWhenVisible } from 'vue-lazy-hydration'
import AppTitle from '@theme/components/atom/AppTitle'
import Breadcrumbs from '@theme/components/molecule/Breadcrumbs'
import CatalogArticles from '@theme/components/catalog/CatalogArticles'
import CatalogBanner from '@theme/components/content/CatalogBanner'
import CatalogContentSwitcher from '@theme/components/catalog/CatalogContentSwitcher'
import CatalogFaq from '@theme/components/catalog/CatalogFaq'
import CatalogFilters from '@theme/components/catalog/CatalogFilters'
import CatalogFiltersActive from '@theme/components/catalog/CatalogFiltersActive'
import CatalogFiltersMobile from '@theme/components/catalog/CatalogFiltersMobile'
import CatalogHelp from '@theme/components/catalog/CatalogHelp'
import CatalogLink from '~/mixins/CatalogLink'
import CatalogProductsRecommended from '@theme/components/catalog/CatalogProductsRecommended'
import CatalogSorting from '@theme/components/catalog/CatalogSorting'
import CatalogSubCategories from '@theme/components/catalog/CatalogSubCategories'
import CatalogTop from '@theme/components/catalog/CatalogTop'
import ContactBox from '@theme/components/shop/ContactBox'
import ContentHeader from '@theme/components/content/ContentHeader'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import LayoutBase from '@theme/components/shop/LayoutBase'
import Loader from '@theme/components/utils/Loader'
import MenuItemPageMixin from '~/mixins/MenuItemPageMixin'
import PageCatalogAboveCatalogProductsRecommendedHook from '@theme/components/catalog/PageCatalogAboveCatalogProductsRecommendedHook'
import PaginatedMixin from '~/mixins/PaginatedMixin'
import Pagination from '@theme/components/molecule/Pagination'
import ProductBox from '@theme/components/product/ProductBox'
import ProductsVisited from '@theme/components/product/ProductsVisited/ProductsVisitedLazy'
import SeoMixin from '~/mixins/SeoMixin'
import UniqueSellingPoints from '@theme/components/shop/UniqueSellingPoints'
import UtilityGridMixin from '~/mixins/UtilityGridMixin'
import VerticalMenu from '@theme/components/shop/VerticalMenu'
import Wysiwyg from '@theme/components/utils/Wysiwyg'
import _ from 'lodash'
import eventDefinitions from '~/events/eventDefinitions'

export default {
  components: {
    AppTitle,
    Breadcrumbs,
    CatalogArticles: hydrateWhenVisible(CatalogArticles),
    CatalogBanner: hydrateWhenVisible(CatalogBanner),
    CatalogContentSwitcher: hydrateWhenVisible(CatalogContentSwitcher),
    CatalogFaq: hydrateWhenVisible(CatalogFaq),
    CatalogFilters: hydrateWhenVisible(CatalogFilters),
    CatalogFiltersActive: hydrateWhenVisible(CatalogFiltersActive),
    CatalogFiltersMobile: hydrateWhenVisible(CatalogFiltersMobile),
    ContentHeader: hydrateWhenVisible(ContentHeader),
    CatalogHelp: hydrateWhenVisible(CatalogHelp),
    CatalogProductsRecommended: hydrateWhenVisible(CatalogProductsRecommended),
    CatalogSorting: hydrateWhenVisible(CatalogSorting),
    CatalogSubCategories: hydrateWhenVisible(CatalogSubCategories),
    CatalogTop: hydrateWhenVisible(CatalogTop),
    ContactBox: hydrateWhenVisible(ContactBox),
    LayoutBase,
    Loader,
    PageCatalogAboveCatalogProductsRecommendedHook: hydrateWhenVisible(PageCatalogAboveCatalogProductsRecommendedHook),
    Pagination: hydrateWhenVisible(Pagination),
    ProductBox: hydrateWhenVisible(ProductBox),
    ProductsVisited: hydrateWhenVisible(ProductsVisited),
    UniqueSellingPoints: hydrateWhenVisible(UniqueSellingPoints),
    VerticalMenu: hydrateWhenVisible(VerticalMenu),
    Wysiwyg: hydrateWhenVisible(Wysiwyg),
  },
  props: {
    bestsellers: Array,
    catalogSettings: Object,
    categoryCms: Object,
    categoryStore: Object,
    initCatalogScope: Object,
    initCatalogState: Object,
    initCanonicalCategories: Object,
    initProducts: Array,
    isSearch: Boolean,
    virtualCategoryCms: Object,
  },
  computed: {
    pageTitle() {
      return this.isSearch
        ? `${this.$t('PageCatalog.content.search.title')} ${this.search}`
        : this.page > 0
          ? `${this.content.pageTitleBase} - ${this.$t('PageCatalog.titlePaginationInfix', {
              current: this.page + 1,
              total: this.pages.length,
            })}`
          : this.content.pageTitleBase
    },
    isLastPage() {
      return this.page === this.pages.length - 1
    },
    catalogItems() {
      const items = this.products.map(product => ({
        type: 'product',
        key: product.id,
        product,
      }))

      if (!this.isSearch && !this.isFiltered) {
        this.content.catalogBanners.forEach(banner => {
          const bannerEntity = banner.banner_catalog_reference[0].entity
          const catalogPosition = banner.banner_catalog_position - this.firstProductIndex
          if (catalogPosition >= 0) {
            items.splice(catalogPosition, 0, {
              type: 'banner_catalog',
              key: bannerEntity._id,
              banner: bannerEntity,
            })
          }
        })
      }

      return items
    },
    catalogAdditionalItems() {
      return this.catalogItems.reduce((acc, item) => acc + (item.type !== 'product' ? 1 : 0), 0)
    },
    content() {
      if (this.isSearch) {
        return {
          title: this.$t('PageCatalog.content.search.titleWithParameter', {
            query: this.search,
          }),
        }
      } else {
        const cmsData = this.virtualCategoryCms ? this.virtualCategoryCms : this.categoryCms
        const getDataByKey = key => {
          const keyValue = _.get(this.filteredCategory, key)
          if (keyValue) {
            if (_.isArray(keyValue)) {
              if (keyValue.length) {
                return keyValue
              }
            } else if (_.isObject(keyValue)) {
              if (_.get(keyValue, 'blocks[0]')) {
                return keyValue
              } else if (_.get(keyValue, 'type') === 'doc' && _.get(keyValue, 'content[0].content[0].text')) {
                return keyValue
              }
            } else {
              return keyValue
            }
          }
          return _.get(cmsData, key)
        }
        const titleSEOSuffix = this.importantValuesTitle ? ' - ' + this.importantValuesTitle : ''
        const baseTitle =
          _.get(this.filteredCategory, 'title') || _.get(this.virtualCategoryCms, 'title') || this.categoryStore.name
        let content = {
          baseTitle,
          title: baseTitle,
          pageTitleBase: getDataByKey('page_meta.seo_title') || baseTitle,
          description: getDataByKey('description') || '',
          textBelowProducts: getDataByKey('text_below_products') || '',
          categoryBanner: getDataByKey('category_banner') || [],
          topContentReferences: (getDataByKey('top_content_reference') || []).map(r => r.entity).filter(e => !!e),
          faqQuestionsReference: (getDataByKey('faq_questions_reference') || []).map(r => r.entity).filter(e => !!e),
          contentReference: (getDataByKey('content_reference') || []).map(r => r.entity).filter(e => !!e),
          videos: getDataByKey('category_video') || [],
          catalogBanners: (getDataByKey('banner_catalog') || []).filter(banner =>
            _.get(banner, 'banner_catalog_reference[0].entity'),
          ),
          content: getDataByKey('content') || null,
          seoDescription: getDataByKey('page_meta.seo_description') || '',
          seoKeywords: getDataByKey('page_meta.seo_keywords') || '',
          portrait: _.get(this.categoryStore, 'content.portrait') || false,
        }
        if (content.title && !_.get(this.filteredCategory, 'title')) {
          content.title += titleSEOSuffix
        }
        if (content.pageTitleBase && !_.get(this.filteredCategory, 'page_meta.seo_title')) {
          content.pageTitleBase += titleSEOSuffix
        }
        return content
      }
    },
    isFiltered() {
      // TODO: Move to global state, it's duplicate with code in CatalogFiltersActive
      return (
        this.selectedTags.length > 0 ||
        this.selectedValues.length > 0 ||
        this.selectedNumericalParameters.length > 0 ||
        this.catalogState.filters.filterInStock.enabled ||
        (this.catalogState.filters.filterPrice.min || this.catalogState.filters.filterPrice.max)
      )
    },
    subcategories() {
      return this.isSearch
        ? this.$store.state.search.catalog.results.menuItems.entities
        : this.menuItem && this.menuItem.children
          ? this.menuItem.children
          : []
    },
    search() {
      return this.$store.state.search.catalog.query
    },
    searchActiveContentType() {
      let activeContentType

      const routeParameter = this.$route.query.show
      const defaultContentType = this.$themeSettings.components.PageCatalog.defaultContentType

      if (this.searchContentTypesWithResults.some(item => item.type === routeParameter)) {
        // Set route parameter if results exist
        activeContentType = routeParameter
      } else if (this.searchContentTypesWithResults.some(item => item.type === defaultContentType)) {
        // Set default content if results exist
        activeContentType = defaultContentType
      } else if (this.searchContentTypesWithResults[0]) {
        // Set first content if exists
        activeContentType = this.searchContentTypesWithResults[0].type
      } else {
        // Show products tab if no results
        activeContentType = 'products'
      }

      return activeContentType
    },
    searchContentTypesWithResults() {
      const contentTypes = []
      const contentTypeNames = _.get(this.$themeSettings, 'components.PageCatalog.searchThrough', [])

      // Prepare product & category result tab
      const results = _.get(this.$store, 'state.search.catalog.results', null)
      const productsAndCategoriesTotal = this.subcategories.length + this.catalogState.productsBeforeFilterCount

      if (productsAndCategoriesTotal) {
        contentTypes.push({
          type: 'products',
          total: productsAndCategoriesTotal,
        })
      }

      // Prepare content result tabs
      contentTypeNames.forEach(type => {
        if (_.get(results, `${type}.total`)) {
          contentTypes.push({
            type,
            total: results[type].total,
          })
        }
      })

      return contentTypes
    },
    transformedSlugTranslations() {
      if (this.selectedValues && this.selectedValues.length && this.selectedValues.length === 1) {
        const slug_translations = _.get(this.selectedValues, '[0].slug_translations')
        if (slug_translations) {
          let output = []
          for (const [key, value] of Object.entries(slug_translations)) {
            if (value) {
              const { canonicalSlugs } = this.categoryStore.canonicalSlugs
              const { slug } =
                canonicalSlugs && canonicalSlugs.length ? canonicalSlugs.find(i => i.language === key) || {} : {}
              output.push({
                language: key,
                slug: slug ? `${slug}/${value}` : value,
              })
            }
          }
          return output.length ? output : false
        }
      }
      return false
    },
    importantFilterSelected() {
      return (
        this.selectedImportantValues.length === 1 && this.selectedImportantValues.length === this.selectedValues.length
      )
    },
    selectedImportantValues() {
      return this.catalogState.parameters
        .filter(p => p.importantFilter)
        .reduce((acc, p) => [...acc, ...p.values.filter(v => v.isSelected)], [])
    },
    pages() {
      return _.chunk(this.catalogState.filteredIds, this.perPage)
    },
    total() {
      return this.catalogState.filteredIds.length
    },
    currentProductsCounter() {
      return `${this.firstProductIndex + 1}–${this.lastProductIndex + 1}`
    },
    selectedNumericalParameters() {
      // TODO: Move to global state, it's duplicite with code in CatalogFiltersActive
      return this.catalogState.parameters.filter(parameter => parameter.selectedRange)
    },
    selectedTags() {
      // TODO: Move to global state, it's duplicite with code in CatalogFiltersActive
      return this.catalogState.tags.filter(tag => tag.isSelected)
    },
    selectedValues() {
      // TODO: Move to global state, it's duplicate with code in CatalogFiltersActive
      const ret = []
      this.catalogState.parameters.forEach(parameter => {
        parameter.values.filter(value => value.isSelected).forEach(value => {
          ret.push(value)
        })
      })
      return ret
    },
    breadcrumbs() {
      if (this.isSearch) {
        return [
          {
            title: !!this.search
              ? this.$t('PageCatalog.content.search.breadcrumbs.results')
              : this.$t('PageCatalog.content.search.breadcrumbs.search'),
            path: this.localePath({ name: 'search' }),
          },
        ]
      }

      if (this.menuBreadcrumbs && this.menuBreadcrumbs.length > 0) {
        return [
          ...this.menuBreadcrumbs,
          ...(this.importantValuesTitle
            ? [
                {
                  title: this.importantValuesTitle,
                  path: this.currentLink.url,
                },
              ]
            : []),
        ]
      } else {
        return [
          {
            title: this.content.title,
            path: this.currentLink.url,
          },
        ]
      }
    },
    importantValues() {
      return this.catalogState.parameters
        .filter(p => p.importantFilter)
        .reduce((acc, p) => [...acc, ...p.values.filter(v => v.isSelected)], [])
    },
    importantValuesTitle() {
      return this.importantValues.map(v => v.names[this.$root._i18n._vm.locale]).join(', ')
    },
    showCatalogHeader() {
      return (
        this.$themeSettings.components.PageCatalog.showCatalogHeader &&
        (!this.$themeSettings.components.ContentHeader.image.required ||
          (this.$themeSettings.components.ContentHeader.image.required &&
            this.content &&
            this.content.categoryBanner &&
            this.content.categoryBanner.length > 0))
      )
    },
    showProductsRecommended() {
      return (
        this.$themeSettings.global.showProductsRecommended &&
        this.$themeSettings.components.PageCatalog.showProductsRecommended &&
        !this.isSearch &&
        !this.isFiltered
      )
    },
    catalogHeaderImage() {
      return this.showCatalogHeader && _.get(this.content, 'categoryBanner[0].path')
        ? this.content.categoryBanner[0].path
        : ''
    },
    verticalMenuTitle() {
      return this.verticalMenuTitleCustom ? this.verticalMenuTitleCustom : this.menuTitle.title
    },
    showSubCategoriesInImportantFiltered() {
      return (
        !this.selectedImportantValues.length ||
        this.$themeSettings.components.PageCatalog.subCategories.showInImportantFilteredCategory
      )
    },
  },
  data() {
    return {
      catalogScope: this.initCatalogScope,
      catalogState: this.initCatalogState,
      currentLink: null,
      layoutGridClass: {},
      columnGridClass: '',
      filteredCategory: this.initCanonicalCategories && this.initCanonicalCategories.filteredCategory,
      canonicalVirtualCategory: this.initCanonicalCategories && this.initCanonicalCategories.canonicalVirtualCategory,
      firstProductIndex: 0,
      lastProductIndex: 0,
      products: this.initProducts,
      verticalMenuTitleCustom: null,
      vuexSearchSubscription: null,
    }
  },
  head() {
    if (this.isSearch) {
      return this.getSeoHead({ noindex: true, nofollow: false })
    } else {
      const data = this.virtualCategoryCms ? this.virtualCategoryCms : this.categoryCms
      return this.getSeoHead({ data })
    }
  },
  mounted() {
    if (!this.isSearch) {
      this.$eventBus.$emit(eventDefinitions.CATALOG.PAGE_VIEW, {
        categoryCms: this.categoryCms,
        categoryStore: this.categoryStore,
        virtualCategoryCms: this.virtualCategoryCms,
      })
    } else {
      this.$eventBus.$emit(eventDefinitions.SEARCH.PAGE_VIEW, {
        query: this.search,
        productsCount: this.total,
      })
      this.$store.commit('search/OPEN_HEADER_SEARCH_INPUT')
    }
  },
  mixins: [CatalogLink, ImageUrlMixin, PaginatedMixin, SeoMixin, MenuItemPageMixin, UtilityGridMixin],
  methods: {
    getSeoMetaDescription() {
      let content = null

      if (this.categoryStore && 'seo_description' in this.categoryStore) {
        content = this.categoryStore.seo_description
      }
      if (this.content.seoDescription && !_.isEmpty(this.content.seoDescription)) {
        content = this.content.seoDescription
      }

      return content
        ? [{ hid: 'description', name: 'description', content }, { property: 'og:description', content }]
        : []
    },
    getSeoLinkCanonical() {
      let link = []
      if (this.page > 0) {
        link.push({
          rel: 'prev',
          href: this.makeAbsoluteUrl(
            this.catalogLink({
              slug: this.$store.state.catalog.slug,
              catalogState: this.catalogState,
              page: this.page - 1,
            }).url,
          ),
        })
      }
      if (this.page < this.pages.length - 1) {
        link.push({
          rel: 'next',
          href: this.makeAbsoluteUrl(
            this.catalogLink({
              slug: this.$store.state.catalog.slug,
              catalogState: this.catalogState,
              page: this.page + 1,
            }).url,
          ),
        })
      }

      let path = this.$route.path
      if (this.canonicalVirtualCategory) {
        path = `/${_.get(this.canonicalVirtualCategory, 'page_meta.slug', '')}`
      }

      link.push({
        rel: 'canonical',
        href: this.canonicalLinkUrl({
          path,
        }),
      })

      return link
    },
    getSeoMetaKeywords() {
      const keywords = this.isSearch
        ? this.$t('PageCatalog.content.search.seo.keywords', {
            query: this.search ? this.search + ', ' : '',
            website: this.$t('globals.website'),
          })
        : this.content.seoKeywords
          ? this.content.seoKeywords
          : null
      if (keywords) {
        return [
          {
            hid: 'keywords',
            name: 'keywords',
            content: keywords,
          },
        ]
      }
      return []
    },
    getSeoMetaTitle() {
      return [
        {
          property: 'og:title',
          content: this.seoTitleSuffix(this.content.title),
        },
      ]
    },
    getSeoTitle() {
      return this.seoTitleSuffix(this.pageTitle)
    },
    async reset() {
      this.page = 0
      this.catalogScope.sort = this.$themeSettings.catalog.defaultSortType
      await this.removeAllFilters()
    },
    removeAllFilters() {
      // TODO: Move to extendable code
      this.catalogState.filters.filterInStock.enabled = false // TODO: Move to extendable code
      this.catalogState.filters.filterPrice = {
        min: null,
        max: null,
      }
      this.selectedTags.forEach(v => (v.isSelected = false))
      this.selectedValues.forEach(v => (v.isSelected = false))
      this.selectedNumericalParameters.forEach(parameter => (parameter.selectedRange = null))
      this.updateFilters()
    },
    setCurrentLink() {
      this.currentLink = this.catalogLink({
        slug: (typeof window === 'object' ? window.location.pathname : this.$route.path)
          .replace('/', '')
          .replace(/\/$/, ''),
        catalogState: this.catalogState,
      })
    },
    stopStoreSubscription() {
      if (this.vuexSearchSubscription) {
        this.vuexSearchSubscription()
      }
    },
    async updateFilters() {
      this.page = 0

      if (this.isSearch) {
        if (this.search < 3) {
          return
        }
        this.catalogScope.search = this.search
      }

      this.$store.commit('catalog/START_LOADING')
      this.setSelectedParametersToScope()

      // Get catalog state given scope, active params from URL, etc.
      this.catalogState = await this.$store.dispatch('catalog/LOAD_CATALOG', {
        catalogScope: this.catalogScope,
        catalogSettings: this.catalogSettings,
      })

      // Load data for current page
      const promises = {
        canonicalCategories: this.$store.dispatch('category/LOAD_CANONICAL_CATEGORIES', {
          catalogState: this.catalogState,
          categoryStore: this.categoryStore,
        }),
        products: this.$store.dispatch('product/LOAD_PRODUCTS', {
          ids: _.chunk(this.catalogState.filteredIds, this.perPage)[this.page],
        }),
      }

      const result = await Promise.allValues(promises)
      this.filteredCategory = result.canonicalCategories && result.canonicalCategories.filteredCategory
      this.canonicalVirtualCategory = result.canonicalCategories && result.canonicalCategories.canonicalVirtualCategory
      this.products = result.products

      this.updateProductIndexes()

      if (!this.isSearch || this.searchActiveContentType === 'products') {
        this.updateCurrentUrl()
      }

      //this.setCanonicals()
      this.$store.commit('catalog/STOP_LOADING')
    },
    updateFiltersListener() {
      this.updateFilters()
    },
    async changePage(page) {
      this.$store.commit('catalog/START_LOADING')
      this.page = page
      this.products = await this.$store.dispatch('product/LOAD_PRODUCTS', { ids: this.pages[this.page] })
      this.updateCurrentUrl()
      this.updateProductIndexes()
      this.$store.commit('catalog/STOP_LOADING')
    },
    async changeSort(code) {
      this.$store.commit('catalog/START_LOADING')
      this.page = 0
      this.updateProductIndexes()
      this.$nextTick()

      this.catalogScope.sort = code

      // Get catalog state given scope, active params from URL, etc.
      this.catalogState = await this.$store.dispatch('catalog/LOAD_CATALOG', {
        catalogScope: this.catalogScope,
        catalogSettings: this.catalogSettings,
      }) // TODO: Add active params from URL

      // Load products for current page
      this.products = await this.$store.dispatch('product/LOAD_PRODUCTS', {
        ids: _.chunk(this.catalogState.filteredIds, this.perPage)[this.page],
      })
      this.updateCurrentUrl()
      this.$store.commit('catalog/STOP_LOADING')
    },
    setSelectedParametersToScope() {
      this.catalogScope.selected = this.catalogState.parameters.reduce((acc, parameter) => {
        if (parameter.selectedRange) {
          return [
            ...acc,
            {
              type: 'parameterNumericalFilter',
              parameterSlug: parameter.slug,
              selectedRange: parameter.selectedRange,
            },
          ]
        } else {
          return [
            ...acc,
            ...parameter.values.filter(value => value.isSelected).map(v => ({
              type: 'parameterValueFilter',
              valueSlug: v.slug,
            })),
          ]
        }
      }, [])

      this.catalogState.filters = typeof this.catalogState.filters === 'undefined' ? {} : this.catalogState.filters

      _.set(this.catalogScope, 'price', [
        this.catalogState.filters.filterPrice.min,
        this.catalogState.filters.filterPrice.max,
      ])

      _.set(this.catalogScope, 'stock', !!this.catalogState.filters.filterInStock.enabled)

      _.set(
        this.catalogScope,
        'selectedTags',
        this.catalogState.tags.filter(tag => tag.isSelected).map(tag => tag.code),
      )
    },
    updateCurrentUrl() {
      // Switch URL
      const newUrl = this.catalogLink({
        slug: this.$store.state.catalog.slug,
        catalogState: this.catalogState,
        page: this.page,
      })

      const queryParts = Object.keys(newUrl.query)
      const query =
        queryParts.length > 0
          ? '?' +
            Object.keys(newUrl.query)
              .reduce((acc, key) => [...acc, `${key}=${newUrl.query[key]}`], [])
              .join('&')
          : ''
      history.replaceState({}, this.pageTitle, `${newUrl.path.replace('//', '/')}${query}`)
      document.title = this.seoTitleSuffix(this.pageTitle)
      this.setCurrentLink()
      this.sendViewEventPage()
    },
    async loadMore() {
      let originalPage = Number(this.$route.query.op) > -1 ? Number(this.$route.query.op) : this.page
      this.page = this.page + 1

      // Switch URL
      const newUrl = this.catalogLink({
        slug: this.$store.state.catalog.slug,
        catalogState: this.catalogState,
        page: this.page,
      })
      await this.$router.replace({ path: newUrl.path, query: { ...newUrl.query, op: originalPage } })

      // Load products
      this.products.push(...(await this.$store.dispatch('product/LOAD_PRODUCTS', { ids: this.pages[this.page] })))
      this.lastProductIndex += this.pages[this.page].length
    },
    updateProductIndexes() {
      if (this.$route && this.$route.query.op && Number(this.$route.query.op) > -1) {
        this.firstProductIndex = Number(this.$route.query.op) * this.perPage
      } else {
        this.firstProductIndex = this.page * this.perPage
      }

      this.lastProductIndex = this.page * this.perPage + (this.pages[this.page] ? this.pages[this.page].length : 0) - 1
    },
  },
  created() {
    this.setCurrentLink()
    this.updateProductIndexes()

    this.layoutGridClass.sidebar = this.convertColumnSizesToString({
      sizes: this.$themeSettings.components.PageCatalog.sidebar.columnSize,
    })
    this.layoutGridClass.mainContent = this.convertColumnSizesToString({
      sizes: this.$themeSettings.components.PageCatalog.sidebar.columnSize,
      invert: true,
    })

    this.columnGridClass = this.computeColumnClass(this.$themeSettings.components.PageCatalog.perLine)

    if (this.isSearch) {
      this.vuexSearchSubscription = this.$store.subscribe(mutation => {
        if (mutation.type === 'search/SET_QUERY' && mutation.payload.location === 'catalog') {
          this.updateFilters()
        }
      })
    }
  },
  beforeMount() {
    this.$catalogEventBus.$on('catalog.sort', this.changeSort)
    this.$catalogEventBus.$on('catalog.removeAllFilters', this.removeAllFilters)
    this.$catalogEventBus.$on('catalog.updateFilters', this.updateFiltersListener)
    this.$catalogEventBus.$on('catalog.reset', this.reset)
  },
  beforeDestroy() {
    this.$catalogEventBus.$off('catalog.sort', this.changeSort)
    this.$catalogEventBus.$off('catalog.removeAllFilters', this.removeAllFilters)
    this.$catalogEventBus.$off('catalog.updateFilters', this.updateFiltersListener)
    this.$catalogEventBus.$off('catalog.reset', this.reset)

    if (this.vuexSearchSubscription) {
      this.vuexSearchSubscription()
    }
  },
  watch: {
    '$route.query.s': function() {
      this.$store.dispatch('search/CHANGE_QUERY', { location: 'catalog', query: this.$route.query.s })
      this.$eventBus.$emit(eventDefinitions.SEARCH.QUERY, {
        location: 'catalog',
        query: this.$route.query.s,
      })
    },
    '$route.query.show': function(newValue, oldValue) {
      // Update URL when user goes from content search tab to product results
      if (oldValue && oldValue !== 'products' && !newValue) {
        this.updateCurrentUrl()
      }
    },
  },
}
