<script>
export default {
  data() {
    return {
      sortedValues: [],
    }
  },
  watch: {
    parameter() {
      // Sort values again when filters update
      this.sortValues()
    },
  },
  created() {
    this.sortValues()
  },
  methods: {
    sortValues() {
      this.sortedValues = this.parameter.values.sort((a, b) => {
        const aEmpty =
          (!a.isSelected && this.parameter.isSelected && a.productIds.complementWithParameter === 0) ||
          (!a.isSelected && !this.parameter.isSelected && a.productIds.intersectWithSelected === 0)

        const bEmpty =
          (!b.isSelected && this.parameter.isSelected && b.productIds.complementWithParameter === 0) ||
          (!b.isSelected && !this.parameter.isSelected && b.productIds.intersectWithSelected === 0)

        if (aEmpty > bEmpty) return 1
        else if (aEmpty < bEmpty) return -1
        else if (
          b.weight === a.weight &&
          (a.names && a.names[this.$store.state.locale.contentLocale]) &&
          (b.names && b.names[this.$store.state.locale.contentLocale])
        ) {
          return a.names[this.$store.state.locale.contentLocale].localeCompare(
            b.names[this.$store.state.locale.contentLocale],
          )
        } else return (b.weight || 0) - (a.weight || 0)
      })
    },
  },
}
</script>
