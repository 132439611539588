import TooltipOpener from '@theme/components/molecule/TooltipOpener'

export default {
  components: {
    TooltipOpener,
  },
  props: {
    isEmpty: { type: Boolean, default: null },
    inputId: { type: String, required: true },
    label: { type: String, required: false },
    tooltip: { type: String, default: null },
    value: { type: Boolean, required: true },
  },
}
