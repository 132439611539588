import AppTitle from '@theme/components/atom/AppTitle'
import Breadcrumbs from '@theme/components/molecule/Breadcrumbs'
import ContactBox from '@theme/components/shop/ContactBox'
import ContentBuilder from '@theme/components/utils/ContentBuilder'
import ImageUrlMixin from '~/mixins/ImageUrlMixin'
import LayoutBase from '@theme/components/shop/LayoutBase'
import MenuItemPageMixin from '~/mixins/MenuItemPageMixin'
import ProductsVisited from '@theme/components/product/ProductsVisited/ProductsVisitedLazy'
import SeoMixin from '@/mixins/SeoMixin'
import UniqueSellingPoints from '@theme/components/shop/UniqueSellingPoints'
import VerticalMenu from '@theme/components/shop/VerticalMenu'

export default {
  mixins: [ImageUrlMixin, MenuItemPageMixin, SeoMixin],
  components: {
    AppTitle,
    Breadcrumbs,
    ContactBox,
    ContentBuilder,
    LayoutBase,
    ProductsVisited,
    UniqueSellingPoints,
    VerticalMenu,
  },
  props: {
    page: Object,
  },
  computed: {
    breadcrumbs() {
      if (this.menuItem) {
        return this.menuBreadcrumbs
      } else {
        return [
          {
            path: this.$route.path,
            title: this.page.title,
          },
        ]
      }
    },
    currentPage() {
      return this.$route.query.p ? parseInt(this.$route.query.p) : 1
    },
  },
  head() {
    return this.getSeoHead()
  },
  data() {
    return {}
  },
}
