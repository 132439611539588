<script>
export default {
  props: {
    catalogState: {
      type: Object,
      required: true,
    },
    parameter: {
      type: Object,
      required: true,
    },
  },
}
</script>
