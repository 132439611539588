var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"d-flex flex-wrap"},_vm._l((_vm.parameterValues),function(parameterValue){return _c('CatalogFiltersParameterValueLink',{key:parameterValue.id,attrs:{"parameter":_vm.parameter,"parameter-value":parameterValue,"catalog-state":_vm.catalogState},scopedSlots:_vm._u([{key:"default",fn:function({ parameterValueIsEmpty }){return _c('div',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(parameterValue.names[_vm.$store.state.locale.contentLocale]),expression:"parameterValue.names[$store.state.locale.contentLocale]"}],staticClass:"CatalogFiltersWidgetImages__button mr-2 mb-2",class:{
        'CatalogFiltersWidgetImages__button--selected': parameterValue.isSelected,
        'CatalogFiltersWidgetImages__button--disabled': parameterValueIsEmpty,
      }},[_c('AppImage',_vm._b({},'AppImage',{
          src: parameterValue.cms.parameter_value_image[0].fileKey,
          width: 50,
          height: 50,
          alt: parameterValue.cms.parameter_value_image[0].alt,
      },false))],1)}}],null,true)})}),1)
}
var staticRenderFns = []

export { render, staticRenderFns }