import CatalogFiltersBox from '@theme/components/catalog/CatalogFiltersBox'
import CatalogFiltersInStock from '@theme/components/catalog/CatalogFiltersInStock'
import CatalogFiltersParameter from '@theme/components/catalog/CatalogFiltersParameter'
import CatalogFiltersPrice from '@theme/components/catalog/CatalogFiltersPrice'
import CatalogFiltersTag from '@theme/components/catalog/CatalogFiltersTag'
import TooltipOpener from '@theme/components/molecule/TooltipOpener'

export default {
  components: {
    CatalogFiltersBox,
    CatalogFiltersInStock,
    CatalogFiltersPrice,
    CatalogFiltersTag,
    CatalogFiltersParameter,
    TooltipOpener,
  },
  props: {
    catalogState: Object,
  },
  methods: {
    update: function() {
      this.$emit('change')
    },
  },
}
