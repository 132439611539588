export default function parseParams(string) {
  return string.split('__').map(s => {
    const matchNumericalFilter = s.match(/([a-zA-Z-]+)_(\d+).(\d+)/)
    if (matchNumericalFilter) {
      return {
        type: 'parameterNumericalFilter',
        parameterSlug: matchNumericalFilter[1],
        selectedRange: [Number(matchNumericalFilter[2]) / 1000, Number(matchNumericalFilter[3]) / 1000],
      }
    } else {
      return {
        type: 'parameterValueFilter',
        valueSlug: s,
      }
    }
  })
}
