import VueSlider from 'vue-slider-component/dist-css/vue-slider-component.umd.min.js'

export default {
  components: {
    VueSlider,
  },
  props: {
    interval: {
      type: Number,
      default: 1,
    },
    min: {
      type: Number,
      required: true,
    },
    max: {
      type: Number,
      required: true,
    },
    unit: {
      type: String,
      required: true,
    },
    value: {
      type: Array,
      default: null,
    },
  },
  data() {
    return {
      inputFromId: `range-slider-from-id-${this._uid}`,
      inputToId: `range-slider-to-id-${this._uid}`,
      isDragging: false,
      selectedValue: null,
    }
  },
  methods: {
    update() {
      if (this.isDragging) {
        return
      }
      this.$emit('change', this.selectedValue)
    },
    resetValue() {
      this.value = [this.min, this.max]
      this.$emit('recalculate', this.selectedValue)
    },
    setDragging(on = true) {
      this.isDragging = on

      if (!on) {
        this.update()
      }
    },
  },
  watch: {
    min() {
      this.resetValue()
    },
    max() {
      this.resetValue()
    },
    value: {
      handler() {
        this.selectedValue = this.value
      },
      immediate: true,
    },
  },
}
