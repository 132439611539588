import _ from 'lodash'

export default {
  props: {
    activeContentType: {
      type: String,
      required: true,
    },
    contentTypesWithResults: {
      type: Array,
      required: true,
    },
  },
  computed: {
    searchQuery() {
      return _.get(this.$store, 'state.search.catalog.query', null)
    },
  },
}
