import CatalogFiltersParameterValueCheckbox from '@theme/components/catalog/CatalogFiltersParameterValueCheckbox'
import CatalogFiltersWidgetMixin from '~/mixins/CatalogFiltersWidgetMixin'
import CatalogFiltersWidgetSortValuesMixin from '~/mixins/CatalogFiltersWidgetSortValuesMixin'

export default {
  mixins: [CatalogFiltersWidgetMixin, CatalogFiltersWidgetSortValuesMixin],
  components: {
    CatalogFiltersParameterValueCheckbox,
  },
  data() {
    return {
      more: false,
      showValuesCount:
        this.initShowValuesCount || this.$themeSettings.components.CatalogFiltersWidgetCheckboxes.showValuesCount,
    }
  },
  props: {
    initShowValuesCount: Number,
  },
  methods: {
    showMore() {
      this.more = true
    },
  },
  computed: {
    hiddenValuesCount() {
      let hiddenValues = 0
      this.sortedValues.forEach((value, index) => {
        if (!value.isSelected && !this.more && index >= this.showValuesCount) {
          hiddenValues++
        }
      })
      return hiddenValues
    },
  },
}
