import CatalogFiltersParameterValueLink from '@theme/components/catalog/CatalogFiltersParameterValueLink'
import CatalogFiltersWidgetMixin from '~/mixins/CatalogFiltersWidgetMixin'
import CatalogFiltersWidgetSortValuesMixin from '~/mixins/CatalogFiltersWidgetSortValuesMixin'
import DetectMobileMixin from '~/mixins/DetectMobileMixin'

export default {
  mixins: [CatalogFiltersWidgetMixin, CatalogFiltersWidgetSortValuesMixin, DetectMobileMixin],
  components: { CatalogFiltersParameterValueLink },
  data() {
    return {
      selectedValue: this.parameter.values.find(value => value.isSelected) || null,
    }
  },
  props: {
    catalogState: Object,
    parameter: Object,
    itemClass: {
      type: String,
      default: '',
    },
  },
  methods: {
    update() {
      this.parameter.values.forEach(value => (value.isSelected = false))
      if (this.selectedValue) {
        this.selectedValue.isChanging = true
        this.selectedValue.isSelected = true
      }
      if (!this.isMobileDetected) this.$scrollTo('#PageCatalog__anchor')
      this.$store.dispatch('catalog/UPDATE_FILTERS')
    },
  },
}
