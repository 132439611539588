import CatalogFiltersWidgetMixin from '~/mixins/CatalogFiltersWidgetMixin'
import CatalogFiltersWidgetSortValuesMixin from '~/mixins/CatalogFiltersWidgetSortValuesMixin'
import DetectMobileMixin from '~/mixins/DetectMobileMixin'

export default {
  mixins: [CatalogFiltersWidgetMixin, CatalogFiltersWidgetSortValuesMixin, DetectMobileMixin],
  data() {
    return {
      selectedValue: this.parameter.values.find(value => value.isSelected) || null,
    }
  },
  computed: {
    fullTitle() {
      return (
        this.parameter.names[this.$store.state.locale.contentLocale] +
        (this.parameter.selectedCount > 0 ? ` (${this.parameter.selectedCount})` : '')
      )
    },
  },
  props: {
    catalogState: Object,
    parameter: Object,
  },
  watch: {
    'parameter.values': function() {
      this.selectedValue = this.parameter.values.find(value => value.isSelected) || null
      if (this.selectedValue) {
        this.selectedValue.isChanging = false
      }
    },
  },
  methods: {
    update() {
      this.parameter.values.forEach(value => (value.isSelected = false))
      if (this.selectedValue) {
        this.selectedValue.isChanging = true
        this.selectedValue.isSelected = true
      }
      if (!this.isMobileDetected) this.$scrollTo('#PageCatalog__anchor')
      this.$store.dispatch('catalog/UPDATE_FILTERS')
    },
  },
}
