import AppButton from '@theme/components/atom/AppButton'
import AppTitle from '@theme/components/atom/AppTitle'
import ContentBuilder from '@theme/components/utils/ContentBuilder'

export default {
  components: {
    AppButton,
    AppTitle,
    ContentBuilder,
  },
  props: {
    contentReference: {
      type: Array,
      required: true,
    },
    faqQuestionsReference: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      faqOpen: null,
    }
  },
  methods: {
    clickFaq(id) {
      if (this.faqOpen !== id) {
        this.faqOpen = id
      } else {
        this.faqOpen = null
      }
    },
  },
}
