<script>
import DetectMobileMixin from '~/mixins/DetectMobileMixin'

export default {
  mixins: [DetectMobileMixin],
  props: {
    catalogState: {
      type: Object,
      required: true,
    },
    parameter: {
      type: Object,
      required: true,
    },
    parameterValue: {
      type: Object,
      required: true,
    },
  },
  computed: {
    parameterValueIsEmpty() {
      return (
        !this.parameterValue.isChanging &&
        ((!this.parameterValue.isSelected &&
          this.parameter.isSelected &&
          this.parameterValue.productIds.complementWithParameter === 0) ||
          (!this.parameterValue.isSelected &&
            !this.parameter.isSelected &&
            this.parameterValue.productIds.intersectWithSelected === 0))
      )
    },
  },
  watch: {
    parameter() {
      this.parameterValue.isChanging = false
    },
  },
  methods: {
    update() {
      this.parameterValue.isChanging = true
      if (!this.isMobileDetected) this.$scrollTo('#PageCatalog__anchor')
      this.$store.dispatch('catalog/UPDATE_FILTERS')
    },
  },
}
</script>
